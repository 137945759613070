<template>
  <div class="section">
    <header-page>
      <span class="text-dark fw-bold-700 size14">Daftar Pembayaran Lainnya</span>
      <template v-slot:action>
        <div class="d-flex w-100 justify-content-end">
          <div>
            <FilterButton />
          </div>
          <div>
            <b-button class="bg-white" @click="$bvModal.show('modal-input')">
              Input Pembayaran Lainnya
            </b-button>
          </div>
        </div>
      </template>
    </header-page>

    <keep-alive>
      <component :is="comp" />
    </keep-alive>
    <ModalInputOther />
  </div>
</template>

<script>
import {
  BButton, BButtonGroup, BDropdown, BDropdownItem, BImg, BPagination, BFormSelect, BModal, VBToggle, BRow, BCol,
} from 'bootstrap-vue'

import HeaderPage from '@/components/HeaderPage.vue'
import TablePayoutOther from '@/components/Payout/Other/TablePayoutOther.vue'
import FilterButton from '@/components/Filter.vue'
import ModalInputOther from '@/components/Payout/Other/Modal/ModalInputOther.vue'

export default {
  title() {
    return 'Pembayaran ke Lainnya'
  },
  components: {
    BButton,
    HeaderPage,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BImg,
    BPagination,
    BFormSelect,
    BModal,
    BRow,
    BCol,
    TablePayoutOther,
    FilterButton,
    ModalInputOther,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      comp: 'TablePayoutOther',
    }
  },
  computed: {
  },
  created() {
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';

</style>
