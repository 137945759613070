<template>
  <b-dropdown
    offset="-160"
    variant="outline-secondary bg-white"
    class="btn-filter mr-1"
    :style="styleName"
    id="button--filter__QuWp"
  >
    <template #button-content>
      <b-img :src="require('@/assets/images/icons/filter.svg')" />
      <span>Filter</span>
    </template>
    <div class="d-flex justify-content-between align-items-center py-1">
      <b-dropdown-item class="px-1 custom__dropdown--item">
        <feather-icon
          size="16"
          class="text-dark fw-bold-800"
          style="margin-right: 5px;"
          icon="ArrowLeftIcon"
        />
        <span class="text-dark fw-bold-700 size14">
          Filter Data
        </span>
      </b-dropdown-item>
      <b-button class="mr-1">
        Terapkan
      </b-button>
    </div>
    <BDropdownDivider />
    <b-form-checkbox
      v-model="checkTanggal"
      class="custom-control-primary m-1"
    >
      Tanggal
    </b-form-checkbox>
    <b-collapse
      id="tanggal"
      class="p-1"
      style="background-color:#F6F7FA;"
      :visible="checkTanggal"
    >
      <div class="d-flex flex-column">
        <b-form-group>
          <v-select
            v-model="selected"
            label="title"
            :options="options"
            class="border border-8"
          />
        </b-form-group>
        <div class="d-flex justify-content-between">
          <b-input-group class="input-group-merge">
            <flat-pickr
              v-model="rangeDate"
              class="form-control"
              :config="{ mode: 'range'}"
              @change.stop="$event.stopPropagation()"
            />
            <b-input-group-append is-text>
              <b-img :src="require('@/assets/images/icons/calendar.svg')" alt="dateIcon" />
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
    </b-collapse>
    <BDropdownDivider />
    <b-form-checkbox
      v-model="checkStatus"
      class="custom-control-primary m-1"
    >
      Status
    </b-form-checkbox>
    <b-collapse
      id="status"
      class="p-1"
      style="background-color:#F6F7FA;"
      :visible="checkStatus"
    >
      <div class="d-flex flex-column">
        <b-form-group>
          <b-form-checkbox
            value="Lunas"
            class="custom-control-primary"
          >
            Lunas
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            value="Belum Lunas"
            class="custom-control-primary"
          >
            Belum Lunas
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            value="Retur"
            class="custom-control-primary"
          >
            Retur
          </b-form-checkbox>
        </b-form-group>
      </div>
    </b-collapse>
    <BDropdownDivider />
    <b-form-checkbox
      v-model="pelanggan.is_show"
      class="custom-control-primary m-1"
    >
      Tipe Pelanggan
    </b-form-checkbox>
    <b-collapse
      id="TipePelanggan"
      class="p-1"
      style="background-color:#F6F7FA;"
      :visible="pelanggan.is_show"
    >
      <b-form-group class="mb-0">
        <v-select
          v-model="pelanggan.checkboxValue"
          label="title"
          :options="pelanggan.options"
          class="border border-8"
        />
      </b-form-group>
    </b-collapse>
  </b-dropdown>
</template>

<script>
import {
  BImg, BDropdown, BDropdownItem, BButton, BDropdownDivider, BCollapse, VBToggle, BFormCheckbox, BFormGroup, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

export default {
  components: {
    BImg,
    BDropdown,
    BDropdownItem,
    BButton,
    BDropdownDivider,
    BCollapse,
    BFormCheckbox,
    vSelect,
    BFormGroup,
    // BFormInput,
    flatPickr,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    styleName: {
      type: String,
      default: '',
    },
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      selected: 'Paling Terkahir',
      options: ['Paling Terakhir', 'Pertama'],
      selectedHari: 'Hari',
      optionsDay: ['Hari'],
      checkTanggal: false,
      checkStatus: false,
      pelanggan: {
        is_show: false,
        options: ['Ritel', 'Non-Ritel'],
        checkboxValue: 'Ritel',
      },
      rangeDate: null,
    }
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.btn-filter {
  .btn-outline-secondary.dropdown-toggle::after {
    background-image: none;
  }
  .dropdown-menu {
    width: 311px;
    height: auto;
    border-radius: 16px;

    .dropdown-item {
      padding: 0;

      &:hover {
        background-color: unset !important;
      }
    }
  }

  .custom__input {
    height: 52px;
    background: #EFF1F5;
    border: 1px solid #E4E5EC;
    border-radius: 8px;
  }

  .dropdown-divider {
    margin: 0;
  }
  .dropdown-menu {
    padding-bottom: 0;
  }
  .flatpickr-input {
    background-color: #EFF1F5 !important;
    height: 52px;
    border: none;
  }
  .input-group-text {
    background-color: #EFF1F5 !important;
    border: none;
  }
}
</style>
